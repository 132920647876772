.TimeNodeContainer {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;
}

.TimeNode {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 200px;
    z-index: 2;
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
}

.InnerDot {
    display: flex;
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    z-index: 10;
    left: 12px;
    top: -58px;
    margin: 0 auto;
}

.OuterDot {
    display: flex;
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: #292929;
    border-radius: 50%;
    z-index: 9;
    left: 0;
    margin: 0 auto;
    top: -72px;
    text-align: center;
}

.Date {
    display: flex;
    position: absolute;
    margin: 0 auto;
    top: -60px;
    left: 75px;
    font-family: var(--font);
    font-size: 25px;
    z-index: 9;
    width: fit-content;
    text-align: center;
}

.Text {
    display: flex;
    position: relative;
    width: fit-content;
    font-family: var(--font);
    left: 0px;
    text-align: center;
    margin: 0 auto;
    top: -15px;
}

.FunFactDate {
    display: flex;
    position: relative;
    left: 0px;
    top: 0px;
    font-family: var(--font);
    font-weight: bolder;
    font-size: 15px;
}

.FunFact {
    display: flex;
    position: relative;
    left: 0px;
    top: -0px;
    font-family: var(--font);
    text-align: center;
}

.factButton {
    display: flex;
    position: absolute;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    background-color: transparent;
    padding: 10px;
    border-width: 1px;
    border-radius: 4px;
    top: 250px;
}

@media only screen and (min-width: 500px) {
    .TimeNodeContainer {
        display: flex;
        position: relative;
        width: 100%;
        z-index: 1;
        text-align: center;
    }

    .TimeNode {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 200px;
        z-index: 2;
        text-align: center;
        margin-left: 25px;
        margin-right: 25px;
    }

    .InnerDot {
        display: flex;
        position: absolute;
        height: 25px;
        width: 25px;
        background-color: #bbb;
        border-radius: 50%;
        z-index: 10;
        left: 12px;
        top: -58px;
        margin: 0 auto;
    }

    .OuterDot {
        display: flex;
        position: absolute;
        height: 50px;
        width: 50px;
        background-color: #292929;
        border-radius: 50%;
        z-index: 9;
        left: 0;
        margin: 0 auto;
        top: -70px;
        text-align: center;
    }

    .Date {
        display: flex;
        position: absolute;
        margin: 0 auto;
        top: -60px;
        left: 75px;
        font-family: var(--font);
        font-size: 25px;
        z-index: 9;
        width: fit-content;
        text-align: center;
    }

    .Text {
        display: flex;
        position: relative;
        width: fit-content;
        font-family: var(--font);
        left: 0px;
        text-align: center;
        margin: 0 auto;
        top: -15px;
    }

    .FunFactDate {
        display: flex;
        position: relative;
        left: 0px;
        top: 0px;
        font-family: var(--font);
        font-weight: bolder;
        font-size: 15px;
    }

    .FunFact {
        display: flex;
        position: relative;
        left: 0px;
        top: -0px;
        font-family: var(--font);
        text-align: center;
    }

    .factButton {
        display: flex;
        position: absolute;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        background-color: transparent;
        padding: 10px;
        border-width: 1px;
        border-radius: 4px;
        top: 250px;
    }
}