:root {
  --font: "Serling Galleria";
  --backgroundColor: #282c34;
}


.App {
  text-align: center;
}

.App-header {
  background-color: var(--backgroundColor);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-size: 75px;
  font-family: var(--font);
  font-weight: bold;
}

.SocialIconGroup {
  display: inline-flex;
  position: relative;
  top: 1em;
  margin-bottom: 50px;
}

.Github {
  display: flex;
  position: relative;
  background-image: url("./Images/Github.png");
  height: 50px;
  width: 50px;
  background-color: transparent;
  border-style: none;
  margin-right: 1em;
  cursor: pointer;
}

.Linkedin {
  display: flex;
  position: relative;
  background-image: url("./Images/Linkedin.png");
  height: 50px;
  width: 50px;
  background-color: transparent;
  border-style: none;
  margin-left: 1em;
  cursor: pointer;
}

.ProfileImg {
  display: flex;
  position: relative;
  border-radius: 50%;
  top: 40px;
}


@media only screen and (min-width: 500px) {

  .App {
    text-align: center;
  }

  .App-header {
    background-color: var(--backgroundColor);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  p {
    font-size: 75px;
    font-family: var(--font);
    font-weight: bold;
  }

  .SocialIconGroup {
    display: inline-flex;
    position: relative;
    top: 1em;
  }

  .Github {
    display: flex;
    position: relative;
    background-image: url("./Images/Github.png");
    height: 50px;
    width: 50px;
    background-color: transparent;
    border-style: none;
    margin-right: 1em;
    cursor: pointer;
  }

  .Linkedin {
    display: flex;
    position: relative;
    background-image: url("./Images/Linkedin.png");
    height: 50px;
    width: 50px;
    background-color: transparent;
    border-style: none;
    margin-left: 1em;
    cursor: pointer;
  }

  .ProfileImg {
    display: flex;
    position: relative;
    border-radius: 50%;
    top: 0px;
  }
}