.ButtonContainer {
    display: flex;
    position: relative;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    text-align: center;
    color: white;
    vertical-align: middle;
    margin-left: 1em;
    margin-right: 1em;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
}

h2 {
    display: flex;
    position: relative;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 25px;
    font-family: var(--font);
}

.ToolTip {
    display: none;
    position: absolute;
    width: auto;
    top: -1.5em;
    right: 0;
    white-space: nowrap;
    background-color: white;
    color: black;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 16px;
}

.ButtonContainer:hover .ToolTip {
    display: flex;
}

.title {
    display: flex;
    position: relative;
    text-align: center;
    padding-top: 5px;
}