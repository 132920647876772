.PDFContainer {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: fit-content;
    text-align: center;
    flex-direction: column;
    color: black;
    background-color: #282c34;
    top: -1px;
}

.ResumePreview {
    display: none;
    position: relative;
    width: 95%;
    height: 800px;
    text-align: center;
    margin: 120px auto;
    border-style: none;

}

.PDFDownload {
    display: flex;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 100px;
    background-color: transparent;
    font-family: var(--font);
    font-size: 25px;
    color: white
}

@media only screen and (min-width: 500px) {
    .PDFContainer {
        display: inline-flex;
        position: relative;
        width: 100%;
        height: fit-content;
        text-align: center;
        flex-direction: column;
        color: black;
        background-color: #282c34;
    }

    .ResumePreview {
        display: flex;
        position: relative;
        width: 50%;
        height: 1000px;
        text-align: center;
        margin: 10em auto;
        border-style: none;

    }
}