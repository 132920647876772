.TimelineContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 440px;
    background-color: #282c34;
    text-align: center;
    overflow: auto;
}

.Timeline {
    display: inline-flex;
    position: relative;
    flex-direction: row;
    background-color: #FFF;
    width: fit-content;
    height: 250px;
    margin: 0 auto;
    padding: 6em;
    row-gap: 100px;
    text-align: center;
}


.DottedLine {
    display: inline-flex;
    position: absolute;
    content: "";
    top: 50px;
    bottom: 0;
    left: 0px;
    right: 0px;
    border-top: 2px dotted #444;
    z-index: 1;
}


@media only screen and (min-width: 500px) {
    .TimelineContainer {
        display: flex;
        position: relative;
        width: 100%;
        height: 448px;
        background-color: #282c34;
        text-align: center;
        overflow: auto;
    }

    .Timeline {
        display: inline-flex;
        position: relative;
        flex-direction: row;
        background-color: #FFF;
        width: fit-content;
        height: 250px;
        margin: 0 auto;
        padding: 6em;
        row-gap: 100px;
        text-align: center;
    }


    .DottedLine {
        display: inline-flex;
        position: absolute;
        content: "";
        top: 50px;
        bottom: 0;
        left: 0px;
        right: 0px;
        border-top: 2px dotted #444;
        z-index: 1;
    }
}