.ProjectsContainer {
    display: inline-block;
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #282c34;
    text-align: center;
    border-style: none;
}

.header {
    display: flex;
    position: relative;
    text-align: center;
    margin: 0 auto;
    font-family: var(--font);
    font-size: 50px;
    color: white;
    margin-top: 100px;
    margin-left: 15px;
}