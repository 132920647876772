.cardContainer {
    display: flex;
    position: relative;
    padding: 15px;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    width: 15em;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 25px;
    margin-right: 25%;
    text-align: left;
    background-color: white;
}

.cardContainer:nth-child(even) {
    margin-left: 25%;
}

.title {
    display: flex;
    position: relative;
    top: -15px;
    margin-bottom: 0px;
}

.description {
    display: flex;
    position: relative;
    padding: 15px;
    margin: 0 auto;
}

.githubButton {
    display: flex;
    position: absolute;
    background-image: url("../../../Images//Github.png");
    height: 50px;
    width: 50px;
    right: 5px;
    bottom: 5px;
    margin-top: 40px;
    background-color: transparent;
    border-style: none;
    filter: invert();
    cursor: pointer;
}



@media only screen and (min-width: 500px) {
    .cardContainer {
        display: flex;
        position: relative;
        padding: 15px;
        border-width: 2px;
        border-style: solid;
        border-radius: 4px;
        width: 30em;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 5px;
        text-align: left;
        background-color: white;
        margin-right: 45%;
    }

    .cardContainer:nth-child(even) {
        margin-left: 45%;
    }

    .title {
        display: flex;
        position: relative;
        top: -15px;
        margin-bottom: 0px;
    }

    .description {
        display: flex;
        position: relative;
        padding: 15px;
        margin: 0 auto;
    }

    .githubButton {
        display: flex;
        position: absolute;
        background-image: url("../../../Images//Github.png");
        height: 50px;
        width: 50px;
        right: 5px;
        bottom: 5px;
        margin-top: 40px;
        background-color: transparent;
        border-style: none;
        filter: invert();
        cursor: pointer;
    }
}